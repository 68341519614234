<template>
  <div class="dbox">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div class="fx_box">
        <div style="
            color: #fff;
            text-align: center;
            font-size: 40px;
            padding-top: 50px;
          ">
          {{ $t('InvitefriendsEarnmoneytogether') }}
        </div>
        <div style="
            color: #fff;
            text-align: center;
            padding-top: 30px;
            width: 850px;
            margin: 0 auto;
          ">
          {{ $t('promote') }}
        </div>

        <!-- 页面tab栏 -->
        <div class="page-tab">
          <div class="tab-box" :class="{'ftab-actived': tab==0}" @click="tabClick(0)">
            <img src="../../assets/invite_tab_1.png" alt="" />
            <span>{{ $t('Howtoinvite') }}</span>
          </div>
          <div class="tab-box" :class="{'ftab-actived': tab==1}" @click="tabClick(1)">
            <img src="../../assets/invite_tab_2.png" alt="" />
            <span>{{ $t('Invitedaily') }}</span>
          </div>
          <div class="tab-box" :class="{'ftab-actived': tab==2}" @click="tabClick(2)">
            <img src="../../assets/invite_tab_3.png" alt="" />
            <span>{{ $t('Invitationdetails') }}</span>
          </div>
        </div>
      </div>

      <!-- 分享页面 -->
      <div v-show="tab == 0" class="fx_content_one">
        <div class="fx_ztop">
          <img class="fx_1" src="../../assets/inviteTitle.png" alt="" />
          <div class="fx_ziti">
            {{ $t('tellmore') }}
          </div>
          <img class="fx_2" src="../../assets/titlewenzi.png" alt="" />
          <div class="fx_tab">
            <div class="fx_pass1">
              <div class="fx_z2">{{ $t('invitationlink') }}</div>
              <div class="fx_fz">
                <div class="fx_yut">{{ link }}</div>
                <div class="fx_ann" @click="copyData">{{ $t('copy') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="fx_ztop" style="height: auto; padding-bottom: 20px;">
          <img class="fx_1" src="../../assets/inviteTitle.png" alt="" />
          <img class="fx_tab" src="../../assets/grade1.png" alt="" />
          <div class="fx_yuyy">
            {{ $t('registration') }}
          </div>
          <div class="ioops">
            <div class="ioops1">{{ $t('Forexample') }}</div>
            <div class="ioops2">
              {{ $t('betcacau') }}
            </div>
          </div>
        </div>
      </div>

      <!-- 代理人页面 -->
      <div v-show="tab == 1" class="fx_content_two">
        <div class="menu-tab">
          <span :class="{ 'tab-actived': agentTab == 0 }" @click="agentTab = 0">{{ $t('month') }}</span>
          <span :class="{ 'tab-actived': agentTab == 1 }" @click="agentTab = 1">{{ $t('today') }}</span>
          <span :class="{ 'tab-actived': agentTab == 2 }" @click="agentTab = 2">{{ $t('week') }}</span>
          <span :class="{ 'tab-actived': agentTab == 3 }" @click="agentTab = 3">{{ $t('year') }}</span>
          <span :class="{ 'tab-actived': agentTab == 4 }" @click="agentTab = 4">{{ $t('yesterday') }}</span>
        </div>
        <div class="list-box">
          <div class="item-box">
            <span>{{ $t('Myagentdetails') }}</span>
            <div class="table-header">
              <span>{{ $t('Number') }}</span>
              <span>{{ $t('Codevalue') }}</span>
              <span>{{ $t('Fullrecharge') }}</span>
              <span>{{ $t('Initialcharge') }}</span>
            </div>
            <div class="table-row">
              <span>{{ agentInfo.oneGradeInviteNum + agentInfo.twoGradeInviteNum }}</span>
              <span>{{ agentInfo.oneTbetcoin + agentInfo.twoTbetcoin }}</span>
              <span>{{ agentInfo.oneRechargeAmount + agentInfo.twoRechargeAmount }}</span>
              <span>{{ agentInfo.oneFirstRecharge + agentInfo.twoFirstRecharge }}</span>
            </div>
          </div>

          <div class="item-box">
            <span>{{ $t('LevelAgent') }}</span>
            <div class="table-header">
              <span>{{ $t('Number') }}</span>
              <span>{{ $t('Codevalue') }}</span>
              <span>{{ $t('Fullrecharge') }}</span>
              <span>{{ $t('Initialcharge') }}</span>
            </div>
            <div class="table-row">
              <span>{{ agentInfo.oneGradeInviteNum }}</span>
              <span>{{ agentInfo.oneTbetcoin }}</span>
              <span>{{ agentInfo.oneRechargeAmount }}</span>
              <span>{{ agentInfo.oneFirstRecharge }}</span>
            </div>
          </div>

          <div class="item-box">
            <span>{{ $t('Level2Agent') }}</span>
            <div class="table-header">
              <span>{{ $t('Number') }}</span>
              <span>{{ $t('Codevalue') }}</span>
              <span>{{ $t('Fullrecharge') }}</span>
              <span>{{ $t('Initialcharge') }}</span>
            </div>
            <div class="table-row">
              <span>{{ agentInfo.twoGradeInviteNum }}</span>
              <span>{{ agentInfo.twoTbetcoin }}</span>
              <span>{{ agentInfo.twoRechargeAmount }}</span>
              <span>{{ agentInfo.twoFirstRecharge }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 邀请人页面 -->
      <div v-show="tab == 2" class="fx_content_three">
        <div class="menu-tab">
          <span :class="{ 'tab-actived': levelTab == 0, 'disabled-btn': loading }"
            @click="levelTabClick(0)">{{ $t('Level1Promotion') }}</span>
          <span :class="{ 'tab-actived': levelTab == 1, 'disabled-btn': loading }"
            @click="levelTabClick(1)">{{ $t('Promotion') }}</span>
        </div>
        <div class="list-box">
          <div class="header-box">
            <span>{{ $t('alcum') }}</span>
            <span>{{ $t('Fullbet') }}</span>
            <span>{{ $t('Fullrecharge') }}</span>
          </div>
          <div v-if="inviteList.length > 0" class="row-box">
            <div v-for="item in inviteList" :key="item.uid" class="item-box">
              <span>{{ item.playername }}</span>
              <span>{{ item.totalbet }}</span>
              <span>{{ item.totalrecharge }}</span>
            </div>
          </div>
          <div v-show="loading"
            class="loading-box"
            v-loading="loading"
            element-loading-text="loading..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0)"></div>
          <div class="load-more-btn"
            :class="{'disabled-btn': loading}"
            @click="loadMore">{{ $t('Loadmore') }}</div>
        </div>
      </div>

      <tabBar />
    </div>
  </div>
</template>

<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from "../../components/tabBar.vue";
import { share, invites } from "../../api/index.js";
export default {
  data() {
    return {
      link: "", // 分享链接
      agent: "", // 代理人数据
      inviteList: [], // 邀请人数据
      tab: 0,
      agentTab: 0,
      page: 0,
      levelTab: 0,
      loading: false,
    };
  },

  computed: {
    // 获取代理人数据
    agentInfo() {
      if (!this.agent)
        return {
          oneGradeInviteNum: 0,
          twoGradeInviteNum: 0,
          oneTbetcoin: 0,
          twoTbetcoin: 0,
          oneRechargeAmount: 0,
          twoRechargeAmount: 0,
          oneFirstRecharge: 0,
          twoFirstRecharge: 0,
        };
      const tab = this.agentTab;
      if (tab == 0) return this.agent.month;
      if (tab == 1) return this.agent.today;
      if (tab == 2) return this.agent.week;
      if (tab == 3) return this.agent.year;
      if (tab == 4) return this.agent.yesterday;
      return null;
    },
  },

  methods: {
    copyData() {
      // 复制数据的逻辑
      // 例如，可以使用浏览器的Clipboard API来复制数据到剪贴板
      navigator.clipboard.writeText(this.link)
        .then(() => {
          // console.log('数据已复制');
          this.$message({
            message: 'success',
            type: 'success'
          });
        })
        .catch((error) => {
          console.error('复制数据失败:', error);
          this.$message({
            message: 'fails',
            type: 'error'
          });
        });
    },

    tyrt() {
      share().then((res1) => {
        //console.log('res1==>', res1);
        if (res1) {
          if (res1.code != 200) return;
          this.agent = res1.data.agent;
          const userinfos = JSON.parse(localStorage.getItem('userinfo'));
          // console.log(userinfos,5555)
          this.link = "https://www.magia-win.com?invite_code="+ userinfos.user.code
        }
      });
    },

    getInvites(callback = null) {
      this.loading = true;
      invites({
        page: this.page,
        level: this.levelTab + 1,
      }).then((res) => {
        // console.log('res==>', res);
        this.loading = false;
        if (!res || res.code != 200) return;
        const data = res.data.data;
        if (data.length == 0) {
          if (typeof callback == "function") callback();
          this.page -= 1;
          return;
        }
        const arr = data.map((item) => item.descendant);
        this.inviteList.push(...arr);
      });
    },

    tabClick(idx) {
      this.tab = idx;
      if (idx == 0 || idx == 1) {
        this.tyrt();
      } else if (idx == 2) {
        this.levelTabClick(0);
      }
    },

    levelTabClick(idx) {
      if (this.loading) return;
      this.levelTab = idx;
      this.page = 1;
      this.inviteList = [];
      this.getInvites();
    },

    loadMore() {
      if (this.loading) return;
      this.page += 1;
      this.getInvites(() => {
        this.$alert(this.$t('tip1_1'), "", {
          confirmButtonText: this.$t('tip2_1'),
          callback: (action) => { },
        });
      });
    },
  },

  mounted() {
    // this.tyrt();
    this.tabClick(0);
  },

  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  },
};
</script>

<style scoped>
.page-tab {
  /* width: 100%; */
  width: 85%;
  /* height: 50px; */
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  /* margin-top: 100px; */
  margin-top: 120px;
}

.page-tab .tab-box {
  width: 320px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 20px;
  cursor: pointer;
}

.tab-box>img {
  width: 40px;
  height: 40px;
}

.tab-box>span {
  color: #fff;
  font-size: 14px;
}

.page-tab  .ftab-actived {
  /* background-color: #683216; */
  background-image: linear-gradient(to right, #683216, #bd5a29);
}

.right {
  margin: 0 100px;
}

.fx_content_two .menu-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  background: hsla(0, 0%, 100%, 0.1);
  margin-top: 10px;
}

.fx_content_two .menu-tab span {
  flex: 1;
  text-align: center;
  line-height: 30px;
  padding: 10px 20px;
  cursor: pointer;
}

.fx_content_two .menu-tab .tab-actived {
  border-radius: 10px;
  background: linear-gradient(180deg, #ef962f, #b56100);
}

.fx_content_two .list-box {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.fx_content_two .item-box>span {
  margin-top: 5px;
  height: 20px;
  color: #fff;
  font-size: 18px;
}

.fx_content_two .table-header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;
  background: hsla(0, 0%, 100%, 0.1);
}

.fx_content_two .table-header span {
  flex: 1;
  text-align: center;
  line-height: 16px;
  padding: 10px 0;
}

.fx_content_two .table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;
  background: hsla(0, 0%, 100%, 0.2);
}

.fx_content_two .table-row span {
  flex: 1;
  text-align: center;
  line-height: 24px;
  padding: 20px 0;
}

.fx_content_three .menu-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
}

.fx_content_three .menu-tab span {
  flex: 1;
  text-align: center;
  line-height: 30px;
  padding: 10px 20px;
  cursor: pointer;
}

.fx_content_three .menu-tab .tab-actived {
  background: #683216;
  border-radius: 30px;
}

.fx_content_three .list-box {
  background: #2a2e3e;
  margin-bottom: 20px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.fx_content_three .header-box,
.fx_content_three .item-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding-top: 15px;
}

.fx_content_three .header-box span,
.fx_content_three .item-box span {
  flex: 1;
  text-align: center;
  line-height: 40px;
}

.fx_content_three .load-more-btn {
  width: 200px;
  line-height: 40px;
  text-align: center;
  margin: 15px auto;
  border-radius: 12px;
  background: hsla(0, 0%, 100%, 0.1);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.fx_content_three .loading-box {
  height: 100px;
}

/* .fx_content_three .loading-box ::v-deep .el-loading-mask {
  background-color: transparent;
} */

.box {
  height: auto;
}

.fx_box {
  /* width: 75%; */
  /* height: 400px; */
  width: 90%;
  background-image: url(../../assets/inviteBgc.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: repeat; */
  /* 2. repeat-x 表示 x轴铺满 */
  /* background-repeat: repeat-x; */
  /* 3. repeat-y 表示 y轴铺满 */
  /* background-size: 100% 400px; */
  background-size: cover;
  background-position: center;
  margin: 60px auto;
  padding-bottom: 60px;
}

.fx_ztop {
  width: 75%;
  height: 500px;
  /* margin-left: 19%; */
  margin-top: 30px;
  border: 1px solid #858383;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
}

.fx_1 {
  width: 300px;
  height: 50px;
}

.fx_ziti {
  width: 700px;
  height: 40px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  margin-top: 30px;
}

.fx_2 {
  width: 400px;
  height: 100px;
  margin-top: 30px;
}

.fx_tab {
  width: 90%;
  height: 233p;
  /* background-color: rgba(255, 255, 255, .1); */
  display: flex;
  justify-content: space-between;
}

.fx_pass1 {
  width: 48%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  margin: 0 auto;
}

.fx_z1 {
  color: gold;
  font-size: 30px;
  padding-top: 20px;
  margin-left: 20px;
}

.fx_z2 {
  color: #797878;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 30px;
}

.fx_fz {
  width: 85%;
  height: 60px;
  color: #fff;
  display: flex;
  border: 1px solid #fff;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.fx_yut {
  width: 85%;
  height: 60px;
  overflow: hidden;
  word-wrap:break-word
}

.fx_ann {
  width: 15%;
  height: 60px;
  background-color: brown;
  text-align: center;
  line-height: 60px;
  border-radius: 10px;
  cursor: pointer;
}

.fx_iuop {
  width: 85%;
  height: 60px;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.fx_yuyy {
  width: 85%;
  color: #fff;
  margin-top: 30px;
}

.ioops {
  width: 85%;
  padding: 20px 0;
  border: 1px solid #818181;
  margin-top: 30px;
}

.ioops1 {
  color: #fff;
  font-size: 26px;
  margin-left: 10px;
}

.ioops2 {
  color: #858383;
  width: 98%;
  margin-left: 1%;
}

.fx_content_three .menu-tab .disabled-btn,
.load-more-btn.disabled-btn {
  cursor: not-allowed;
}
</style>
