<template>
  <div class="hello">
    <!-- <el-menu default-active="2" class="el-menu-vertical-demo" v-show="aa" :style="{ width: aa }" @open="handleOpen"
      @close="handleClose" background-color="rgb(20,18,18)" text-color="#fff" active-text-color="#ffd04b"> -->
    <el-menu class="menu-box el-menu-vertical-demo"
      :default-active="activeIdx"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      background-color="#071f16"
      text-color="#fff"
      active-text-color="#ffd04b">
        <div v-for="(item, idx) in menus" :key="item.index">
          <template v-if="idx == 0">
            <el-submenu :index="item.index">
              <template slot="title">
                <i class="el-icon-locatio"></i>
                <img @click="openbox" src="../assets/closeIcon.png"
                  style="width: 30px; height: 30px; margin-left: -35px;" alt="">
                <router-link :to="item.path">
                  <img src="../assets/logo2.7b35e1e5.png" style="width: 160px; height: 50px;" alt="" v-show="bb">
                </router-link>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <router-link :to="item.path">
              <el-menu-item :index="item.index">
                <img :src="item.icon" style="width: 30px; height: 30px;" alt="">
                <span class="menu-txt" style="color: #fff;" v-show="bb">{{ $t(item.name) }}</span>
              </el-menu-item>
            </router-link>
          </template>
        </div>
    </el-menu>
  </div>
</template>

<script>
import { EventBus } from '@/load/event-bus.js';
export default {
  data() {
    return {
      menus: [
        {index: '0', path: '/', icon: '', name: ''},
        {index: '1', path: '/index', icon: require('../assets/menu1.png'), name: 'Firstcharge'},
        {index: '2', path: '/topup', icon: require('../assets/menu2.png'), name: 'ReloadBonuses'},
        {index: '4', path: '/telegram', icon: require('../assets/menu4.png'), name: 'joinus'},
        {index: '6', path: '/invite', icon: require('../assets/menu6.png'), name: 'InvitationBonus'},
        {index: '7', path: '/VipSrc', icon: require('../assets/menu7.png'), name: 'VIPIntroduction'},
        {index: '8', path: '/login', icon: require('../assets/menu8.png'), name: 'Personalranking'},
        {index: '10', path: '/message', icon: require('../assets/zf_xinxiang.png'), name: 'Contactus'},
        {index: '9', path: '/customer', icon: require('../assets/menu9.png'), name: 'customerservice'},
      ],
      activeIdx: '0',
      isCollapse: true,
      // aa: '13%',
      bb: true,
    };
  },

  watch: {
    $route(to, from) {
      // console.log('route==>', to, from);
    }
  },

  created() {
    // 订阅菜单栏选中状态改变事件 (由其他页面触发去改变当前菜单选中状态)
    EventBus.$on('updateMenuSelectChange', (path) => {
        if (!path) return;
        this.activeIdx = this.findMenuIndexByPath(path) || '0';
    });
  },

  mounted() {
    // 获取当前路由路径
    const path = this.$route.path;
    this.activeIdx = this.findMenuIndexByPath(path) || '0';
  },

  methods: {
    findMenuIndexByPath(path) {
      if (!path) return '';
      for(let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].path == path) return this.menus[i].index;
      }
      return '';
    },

    openbox() {
      // this.aa = this.aa === '13%' ? '5%' : '13%';
      this.bb = !this.bb
      this.$emit('expand', this.bb);
    },

    handleOpen(idx, idxPath) {
      // console.log('open==>', idx, idxPath);
    },

    handleClose(idx, idxPath) {
      // console.log('close==>', idx, idxPath);
    },

    handleSelect(idx, idxPath) {
      // console.log('select==>', idx, idxPath);
      this.activeIdx = idx;
    },
  }
}
</script>

<style scoped>
/* .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 150px;
    min-height: 900px;
    position: fixed;
    border: 1px solid black;
    z-index: 999;
    top: 0;
  } */

  .hello {
    height: 100%;
  }

  .menu-box {
    height: 100%;
    position: relative;
    z-index: 99;
  }
  .menu-box>div:nth-child(1) {
    padding-top: 10px;
  }
  .el-menu{
    border:none;
  }
  .el-menu a{
    text-decoration:none;
    color:#fff;
    margin-left:10px;
  }

  .menu-txt {
    margin-left: 12px;
  }

  .el-submenu ::v-deep .el-submenu__title:hover {
    background-color: unset !important;
  }

  .el-submenu ::v-deep .el-submenu__title>img {
    width: 40px !important;
    height: 40px !important;
  }

  .el-menu-item.is-active {
    background-color:rgba(255, 255, 255, .1) !important;
  }

  .el-menu-item:hover:not(.is-active) {
    /* outline: 0;
    background-color: #ecf5ff; */
    /* background-color: unset; */
    background-color: rgba(204, 204, 204, 0.5) !important;
  }

  .el-menu .el-submenu ::v-deep .el-submenu__icon-arrow {
    visibility: hidden;
  }

  .el-menu-item {
    overflow: hidden;
  }

  .el-menu-item::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgba(204, 204, 204, 0.8);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
  }

  .el-menu-item:focus::before {
    animation: wave 1s ease-out;
  }

  @keyframes wave {
    0% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }

    100% {
      transform: scale3d(25, 25, 1);
      opacity: 0;
    }
  }
</style>
